import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { DowwnloadService } from 'src/app/services/dowwnload.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-download-data',
  templateUrl: './download-data.component.html',
  styleUrls: ['./download-data.component.scss']
})
export class DownloadDataComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;
  @Input() stateModalDownload: boolean;
  @Input() urlArcgis2: any;
  @Output() reponseStateModalDownload = new EventEmitter();

  data:any = [];
  objectForm: FormGroup;

  titleModal: string = 'Descarga de Datos';
  isButtonDisabled: boolean = false;

  currentType: string;
  currentChannel: string;

  channelsOriginal: any = [];
  channels: any = [];
  types: any = [];

  public myDatePickerOptions: IMyOptions = {
    // Your options
    //  dateFormat: 'DD/MM/YYYY'
  };

  constructor( private dowwnloadService: DowwnloadService, private fb: FormBuilder) { }

  ngOnInit(): void {
    
    this.initForm();
    this.getTypes(this.typeService);
    this.getChannels();
    //this.getcrackmeter(); 
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModalDownload) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES: "+this.stateModalDownload);

      this.addModal.show();
      this.reponseStateModalDownload.emit(false);
    }
  }


  initForm(){

    this.objectForm = this.fb.group({
      'channel': ['', Validators.required],
      'type': ['', Validators.required],
      'fecha_inicio': ['', Validators.required],
      'fecha_fin': ['', Validators.required],
      // 'id': [''],
    });
  }

  dataFilter: any;
  downloadData(){

    // const startDate = new Date('2023-10-25T00:00:00'); // Fecha de inicio
    // const endDate = new Date('2023-10-26T23:59:00');   // Fecha de finalización

    // this.dataFilter = this.data;
    // this.dataFilter = this.dataFilter.filter((object) => object.channel_id == '1508');
    // this.dataFilter = this.dataFilter.filter((object) => {
    //   // Suponemos que object.date es la propiedad que contiene la fecha que deseas filtrar.
    //   const objectDate = new Date(object.fecha);
    //   console.log('DATE: '+objectDate);
    //   // Comprueba si la fecha de objectDate está dentro del rango de startDate y endDate.
    //   return objectDate >= startDate && objectDate <= endDate;
    // });

    const modifiedData = this.data.map(obj => {
      const { channel_id, ...restoDeDatos } = obj;
      return restoDeDatos;
    });
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedData);
    const workbook: XLSX.WorkBook = { Sheets: { 'Datos': worksheet }, SheetNames: ['Datos'] };
    
    const nameFile = `${this.nameDownload}_${this.currentChannel}.xlsx`;

    XLSX.writeFile(workbook, nameFile );
    

    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
    // const workbook: XLSX.WorkBook = { Sheets: { 'Datos': worksheet }, SheetNames: ['Datos'] };
  
    // XLSX.writeFile(workbook, 'datos.xlsx');

    // const selectedColumns = this.jsonData.map(({ Nombre, Ciudad }) => ({ Nombre, Ciudad }));
    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedColumns);
    // const workbook: XLSX.WorkBook = { Sheets: { 'Datos': worksheet }, SheetNames: ['Datos'] };
  
    // XLSX.writeFile(workbook, 'datos.xlsx');
    this.isButtonDisabled = false;

  }

  download(){

    this.isButtonDisabled = true;
    this.getData(this.currentChannel, this.currentType);
  }

  openModal(){

    this.addModal.show();
  }

  nameDownload: string;
  changedType(event){

    console.log('EVENT: '+event.gid);
    this.channels = this.channelsOriginal.filter((object) => object.sensor_type_id == event.gid && object.id != 0);
    this.currentType = event.name;
    this.nameDownload = event.type2;
  }

  changedChannel(event){

    console.log('EVENT: '+event.id);
    this.currentChannel = event.id;
  }

  getcrackmeter(){

    this.dowwnloadService.getCrackmeter().subscribe({
      next: (resp) => { 
        this.data = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

  typeService = '';

  getTypes(type: string){

    this.dowwnloadService.getTypes().subscribe({
      next: (resp) => { 
        this.types = resp;

        console.log('this.urlArcgis2.uid: '+this.urlArcgis2.uid);
        if(this.urlArcgis2.uid == '65fd9f75aa0e312a459590ca'){
          this.types = this.types.filter(object => object.name == "tdr" || object.name == "ipi");
        }else{
          this.types = this.types.filter(object => object.name == "tiltmeter" || object.name == "crackmeter" || object.name == "laser" || object.name == "tiltmeter2");
        }

        console.dir('Response Types: ' + resp); 
        const dispositivosTransformados = this.types.map(dispositivo => {

          const tipoTransformado = {
            "tiltmeter": "Tilmeter biaxial",
            "crackmeter": "Crackmeter",
            "laser": "Distanciómetro láser",
            "tiltmeter2": "Tiltmeter triaxial",
            "tdr": "TDR",
            "ipi": "Inclinómetros"
        };

        const type = tipoTransformado[dispositivo.name] || dispositivo.name; // Usamos el objeto de mapeo

        return {
              gid: dispositivo.gid, // Cambiamos gid a id
              name: dispositivo.name, // Cambiamos name a type
              type2: type,
              tableName: dispositivo.tabla_registros // Mantenemos tabla_registros como tableName
          };
      });
      this.types = dispositivosTransformados;

      console.dir('Response Types: ' + this.types); 

        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

  getChannels(){

    this.dowwnloadService.getChannels().subscribe({
      next: (resp) => { 
        this.channelsOriginal = resp;
        //this.channels = resp;
         console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

  getData(id: string, type: string){

    this.dowwnloadService.getData(id, type).subscribe({
      next: (resp) => { 
        this.data = resp;
        this.downloadData();
        console.log('Response: ' + resp); 
        }, 
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
      }
    });
  }

}

